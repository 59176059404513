.sg-card {
    width: 200px;
    height: 200px;
    background-color: aqua;
}

.tech-list {
    max-width: max-content;
    max-height: 100vh;
    overflow: auto;
}
 
.sumury{
    max-width: max-content;
    max-height: 100vh;
    overflow: auto;
}

.center-sumury{
    max-width: max-content;
    max-height: 100vh;
    overflow: auto;
}

.card {
   margin: 1rem;
}