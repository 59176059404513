* {
    box-sizing: border-box;
}

/* Create two equal columns that floats next to each other */
.column {
    float: left;
    width: 33.33%;
    padding: 10px;
    height: 500px;
    /* Should be removed. Only for demonstration */
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

.g-bg-colour-a {
    background-color: #aaa
}

.g-bg-colour-b {
    background-color: #bbb
}

.square-left {
    height: 100%;
    width: 50%;
    background-color: #c8eed3;
    border-radius: 3px;
    overflow-x: auto;
    align-items: center;
    float: left;
}


.square-right {
    height: 100%;
    width: 50%;
    background-color: #9FB6A6;
    border-radius: 3px;
    float: right;
    align-items: center;
    overflow-x: auto;
}

.circle-button-wm {
    border-radius: 50%;
    margin-left: 0.5em;
    margin-top: 0.5em;
    width: 150px;
    height: 150px;
    background-color: rgb(206, 231, 233);
    float: left;
    font-size: xxx-large;
}

.circle-button-selected {
    border: 2px solid #609;;
}


.circle-button-wm:hover {
    border: 2px solid #609;;
}

.circle-input {
    border-radius: 50%;
    border: 2px solid #609;
    padding: 20px; 
    width: 150px;
    height: 80px;
    margin-left: 0.5em;
    margin-top: 0.5em;
    font-size: 25px;
} 

.divide-up {
    width: 100%;
    height: 96vh;
    overflow: hidden;
    border: 2px solid rgb(75, 37, 12);
    align-items: center;
    max-height: 96vh;
}

.divide-down {
    width: 100%;
    height: 16vh;
    border: 2px solid #609;
    overflow: auto;
    position: relative;
}

.divide-left {
    width: 75%;
    height: 96vh;
    overflow: auto;
    align-items: center;
    max-height: 96vh;
}

.divide-right {
    width: 25%;
    height: 78vh;
    overflow: auto;
    overflow-x: hidden;
    max-height: 79vh;
    float: right;
}
.divide-right-down{
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
    /* margin-top: 20px; */
    margin-block:auto;
}

.divide-down table {
    width: 100%;
}

.margin-top-20 {
    margin-top: 20px;
}