.App {
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.bi:hover {
  color: #0d6efd !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.sticky-top-left{
  top: 20px;
  left : 10px;
  position: relative;
  width: 40px;
  height: 40px;
  background-color: #0d6efd;
  border-radius: 10px;
}

.sticky-top-left i{
  color: white;
} 

@media only screen and (max-width : 992px) {
  .sticky-top-left {
    display: none;
  }
  .display-none {
    display:block !important;
  }
}

.display-none {
  display:none !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-input {
  position: relative;
  width: fit-content;
}

.spinners {
  position: absolute;
  right: 0;
  top: 50%;
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 1px;
  transform: translateY(-50%);
  margin-right:20px ;
}

.spinner {
  font-size: 7px;
  border: none;
  padding: 0 1px;
  height: 25px;
  width: 25px;
}

.spinner:hover {
  background: lightgrey;
}