table.table {
    table-layout: fixed;
}

table.table tr th,
table.table tr td {
    border-color: #e9e9e9;
}

table.table th i {
    font-size: 13px;
    margin: 0 5px;
    cursor: pointer;
}

table.table th:last-child {
    width: 100px;
}

table.table td a {
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
    min-width: 24px;
}

table.table td a.add {
    color: #27C46B;
}

table.table td a.edit {
    color: #FFC107;
}

table.table td a.delete {
    color: #E34724;
}

table.table td i {
    font-size: 19px;
}

table.table .form-control {
    height: 32px;
    line-height: 32px;
    box-shadow: none;
    border-radius: 2px;
}

table.table .form-control.error {
    border-color: #f50000;
}