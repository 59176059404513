/* Webpixels CSS */
/* Utility and component-centric Design System based on Bootstrap for fast, responsive UI development */
/* URL: https://github.com/webpixels/css */

@import url(https://unpkg.com/@webpixels/css@1.1.5/dist/index.css);

/* Bootstrap Icons */
@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css");

.bigger-z-index {
    z-index: 1000;
}

.right{
    margin-left: 3em;
    margin-right: 3em;
    position: relative;
    flex-grow: 1;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    font-weight: bold;;
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}
td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.float-right {
    float: right;
    margin-right: 20px;
    margin-bottom: 20px;
}

@media (min-width: 992px) {
    .navbar-vertical.navbar-expand-lg.show {
        max-width: 200px;
        border-radius: 0;
    }
}